import React, { useEffect, useState, useCallback } from "react";
import en_translation from "../../localization/en/translation.json";
import { AppButton, AppIcon, AppToggleSwitch } from "../../theme";
import { add, trash, edit } from "../../theme/icons";
import List from "../../components/editableGrid/list/list";
import axios from "axios";
import { API_URL } from "../../store/api";
import "./quickLinks.scss";
/* eslint-disable */
type Props = {
  projectId: string;
  isAdmin: boolean;
};

type Link = {
  id: string;
  title: string;
  url: string;
  visibility: boolean;
};

const QuickLinks: React.FC<Props> = ({ projectId, isAdmin }) => {
  const [links, setLinks] = useState<Link[]>([]);
  const [isNewLink, setIsNewLink] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<string | null>(null); // Track which row is being edited
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editData, setEditData] = useState<Link | null>(null); // Track the data being edited
  // Quick Links API
  const getProjectQuickLinks = useCallback(
    async (projectId: string) => {
      const url = `${API_URL}/api/v1/projects/${projectId}/quick-links`;
      try {
        setIsLoading(true);
        const response = await axios.get(url);
        setLinks(response.data);
        setIsNewLink(false);
        setIsEditing(null);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching project quick links:", error);
      }
    },
    [projectId]
  );

  const addProjectQuickLink = async (projectId: string, link: Link) => {
    const url = `${API_URL}/api/v1/projects/${projectId}/quick-links`;
    try {
      await axios.post(url, link);
      getProjectQuickLinks(projectId);
    } catch (error) {
      getProjectQuickLinks(projectId);
      console.error("Error adding project quick link:", error);
    }
  };

  const updateProjectQuickLink = async (projectId: string, link: Link) => {
    const url = `${API_URL}/api/v1/projects/${projectId}/quick-links/${link.id}`;
    try {
      await axios.put(url, link);
      getProjectQuickLinks(projectId);
    } catch (error) {
      getProjectQuickLinks(projectId);
      console.error("Error updating project quick link:", error);
    }
  };

  const deleteProjectQuickLink = async (projectId: string, linkId: string) => {
    const url = `${API_URL}/api/v1/projects/${projectId}/quick-links/${linkId}`;
    try {
      await axios.delete(url);
      getProjectQuickLinks(projectId);
    } catch (error) {
      getProjectQuickLinks(projectId);
      console.error("Error deleting project quick link:", error);
    }
  };

  useEffect(() => {
    getProjectQuickLinks(projectId);
  }, [getProjectQuickLinks, projectId]);

  const handleCellValueChanged = (params: any) => {
    const updatedLink = { ...params.data, editData };
    if (isNewLink) {
      addProjectQuickLink(projectId, updatedLink);
      setIsNewLink(false);
    } else if (!editData && isEditing) {
      updateProjectQuickLink(projectId, updatedLink);
    }
    setIsEditing(null);
  };

  const handleDelete = async (id: string) => {
    await deleteProjectQuickLink(projectId, id);
  };

  const handleAddLink = () => {
    const newLink: Link = {
      id: Date.now().toString(), // Generate a unique ID
      title: "",
      url: "",
      visibility: false,
    };
    setLinks((prevLinks) => [...prevLinks, newLink]);
    setIsNewLink(true);
    setIsEditing(newLink.id);
  };

  const actionCellRenderer = (props: any) => {
    const { data } = props;
    return (
      <div>
        {isAdmin && (
          <>
            <AppIcon
              icon={edit}
              className="edit-icon"
              name="edit"
              onClick={() => onCellClicked(props)}
            />
            <AppIcon
              icon={trash}
              className="delete-icon"
              name="delete"
              onClick={() => handleDelete(data.id)}
            />
          </>
        )}
      </div>
    );
  };
  const stopEditing = (params: any) => {
    const updatedLink = params.data;
    setEditData(updatedLink);
    params.api.stopEditing();
    handleCellValueChanged(params);
  };
  const onCellClicked = (params: any) => {
    setIsEditing(params.data.id);
    params.api.startEditingCell({
      rowIndex: params.node.rowIndex,
      colKey: "title",
    });
  };
  const handleToggleClick = (params: any, checked: boolean) => {
    const updatedLink = params.data;
    updatedLink.visibility = checked;
    params.setValue(checked);
    setEditData(updatedLink);
    params.api.stopEditing();
    setTimeout(() => {
      handleCellValueChanged(params);
    }, 10000);
    onCellClicked(params);
  };
  const toggleRender = (params: any) => {
    return (
      isAdmin && (
        <AppToggleSwitch
          newDesign={true}
          id="visibility"
          disabled={isEditing !== params.data.id}
          checked={params.value}
          onChange={(e: any) => handleToggleClick(params, e.target.checked)}
        />
      )
    );
  };

  const columns = [
    {
      headerName: "Link title (Max 50 chars)",
      field: "title",
      flex: 6,
      suppressSizeToFit: true,
      editable: isAdmin,
      cellEditor: "agTextCellEditor",
      cellEditingStopped: stopEditing,
      cellEditorParams: { maxLength: 50 },
    },
    {
      headerName: "URL",
      field: "url",
      flex: 14,
      suppressSizeToFit: true,
      cellEditingStopped: stopEditing,
      editable: isAdmin,
      cellEditorParams: { validUrl: true },
      cellEditor: "agTextCellEditor",
    },
    isAdmin
      ? {
          headerName: "Client visibility",
          field: "visibility",
          flex: 3,
          suppressSizeToFit: true,
          editable: false,
          cellRenderer: toggleRender,
        }
      : null,
    isAdmin
      ? {
          headerName: "",
          field: "actions",
          flex: 2,
          suppressSizeToFit: true,
          editable: false,

          cellRenderer: actionCellRenderer,
        }
      : null,
  ];

  const defaultColDef = {
    sortable: false,
    filter: false,
    editable: false,
  };
  const listColumns = columns.filter((col) => col != null);
  const data = Array.isArray(links)
    ? isAdmin
      ? links
      : links.filter((x) => x.visibility == true)
    : [];
  return (
    <section className="quick-links">
      <span>{"Quick Links"}</span>
      {isAdmin && (
        <div className="add-new-link-button">
          <AppButton
            id="add-link-button"
            onClick={handleAddLink}
            size="medium"
            variant="secondary"
            className="containedSecondary"
            disabled={isEditing !== null || isNewLink}
          >
            <AppIcon icon={add} className="add-icon" />
            {en_translation.add_new_link}
          </AppButton>
        </div>
      )}
      {!isLoading && data.length < 1 && (
        <div className="add-quick-links">
          <div className="no-link-found">
            <span className="no-links">{en_translation.no_link_found}</span>
          </div>
          <div>
            <span className="start-addition-links">
              {en_translation.start_add_quick_link}
            </span>
          </div>
          {isAdmin && (
            <div>
              <AppButton
                id="add-link-button"
                onClick={handleAddLink}
                size="medium"
                className="containedSecondary"
              >
                <AppIcon icon={add} className="add-icon" />
                {en_translation.add_quick_link}
              </AppButton>
            </div>
          )}
        </div>
      )}
      {data.length > 0 && (
        <div className="quick-links-list">
          <List
            rowHeight={30}
            rowSelection="single"
            firstColumnBorderRight={true}
            columnConfig={listColumns}
            defaultColDef={defaultColDef}
            rowData={data}
            paginate={false}
            onCellValueChanged={handleCellValueChanged}
            gridOptions={{
              headerHeight: 30,
              defaultColDef: defaultColDef,
              sizeColumnsToFit: true,
              editType: "fullRow",
              autoSizeStrategy: {
                type: "fitGridWidth",
                defaultMinWidth: 100,
              },
            }}
          />
        </div>
      )}
    </section>
  );
};

export default QuickLinks;
