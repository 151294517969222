import React, { FC, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";

import { BundleClass } from "../bundleCard/models/bundleClass";
import { AppCategory } from "../appCard/models/appCategory";
import { AppClass } from "../appCard/models/appClass";
import t from "../../localization/en/translation.json";
import { AppButton, AppCheckbox, AppIcon, AppTooltip } from "../../theme";
import { downArrow, editPen, info } from "../../theme/icons";
import {
  IFormModel,
  EProjectCategory,
  IPaceFieldState,
  IApproverFieldState,
} from "../../containers/createNewProject/formStateModel";
import {
  CountryLookupModel,
  IServiceLines,
  lookupModel,
  RegionLookupModel,
  SubSectorLookupModel,
  SubSubSectorLookupModel,
} from "../../containers/createNewProject/lookupModel";
import PricingProvisionPolicy from "../projectFormNew/projectPricingProvisionPolicy";

import "./confirmAndCreateProject.scss";

interface IProps {
  projectName?: string;
  projectType?: string;
  bundle?: BundleClass;
  apps: AppClass[];
  appCategories: AppCategory[];
  handleStepClick: Function;
  formState: IFormModel;
  projectCategories: lookupModel[];
  clientSizes: lookupModel[];
  projectTypes: lookupModel[];
  projectAreas: lookupModel[];
  projectRegions: RegionLookupModel[];
  projectCountries: CountryLookupModel[];
  sectors: lookupModel[];
  subSectors: SubSectorLookupModel[];
  subSubSectors: SubSubSectorLookupModel[];
  serviceLines: IServiceLines[];
  dataLocations: lookupModel[];
  paceFieldState: IPaceFieldState;
  approverFieldState: IApproverFieldState;
}

const ConfirmAndCreateProject: FC<IProps> = ({
  handleStepClick,
  apps,
  ...rest
}) => {
  const { formState, paceFieldState, approverFieldState } = rest;

  const [showTitleTooltip, setShowTitleTooltip] = useState(false);

  const projectCategory =
    rest.projectCategories.find(
      (item) => item.id === formState.projectCategory.fieldVal
    )?.name || "";
  const paceProject = paceFieldState.selectedValue?.[0]
    ? `${paceFieldState.selectedValue?.[0].paceId} (${paceFieldState.selectedValue?.[0].projectName})`
    : "";
  const ppeddApprover = approverFieldState.selectedValue?.[0]
    ? approverFieldState.selectedValue?.[0].displayName
    : "";
  const projectName = formState.projectName?.fieldVal || "";
  const projectConfidential = formState.projectConfidential.fieldVal;
  const projectClientName = formState.projectClientName.fieldVal;
  const clientSize =
    rest.clientSizes.find((item) => item.id === formState.projectSize.fieldVal)
      ?.name || "";
  const projectType =
    rest.projectTypes.find((item) => item.id === formState.projectType.fieldVal)
      ?.name || "";
  const projectArea =
    rest.projectAreas.find((item) => item.id === formState.area.fieldVal?.id)
      ?.name || "";
  const projectRegion =
    rest.projectRegions.find(
      (item) => item.id === formState.region.fieldVal?.id
    )?.name || "";
  const projectCountry =
    rest.projectCountries.find(
      (item) => item.id === formState.country.fieldVal?.id
    )?.name || "";
  const projectSector =
    rest.sectors.find((item) => {
      const fieldVal = formState.projectSector.fieldVal;
      return (
        item.id === (typeof fieldVal === "object" ? fieldVal.id : fieldVal)
      );
    })?.name || "";

  const projectSubSector =
    rest.subSectors.find((item) => {
      const fieldVal = formState.projectSubSector.fieldVal;
      return (
        item.id === (typeof fieldVal === "object" ? fieldVal.id : fieldVal)
      );
    })?.name || "";
  const projectSubSubSector =
    rest.subSubSectors.find((item) => {
      const fieldVal = formState.projectSubSubSector?.fieldVal;
      if (fieldVal === null || fieldVal === undefined) {
        return false;
      }
      return (
        item.id === (typeof fieldVal === "object" ? fieldVal.id : fieldVal)
      );
    })?.name || "-";
  const projectServiceLine =
    rest.serviceLines.find(
      (item) => item.id === formState.projectServiceLine.fieldVal
    )?.name || "";
  const projectDataLocation =
    rest.dataLocations.find(
      (item) => item.id === formState.projectLocation.fieldVal
    )?.name || "";
  const projectDescription = formState.projectDesc.fieldVal;

  let projectMFAFlag = formState.projectMFAEnabled.fieldVal === "true";

  const checkEllipsis = ({ target }, containerName, maxLine) => {
    const ctx = document.createElement("canvas").getContext("2d");

    if (!ctx) {
      return;
    }
    const text = ctx.measureText(target.innerText);
    const container = document.getElementsByClassName(containerName)
      ? document.getElementsByClassName(containerName)[0]
      : null;
    const containerWidth = container ? container.offsetWidth : 0;
    const minWidth = containerWidth > 253 ? 96 : 92;

    setShowTitleTooltip(text.width > (containerWidth - minWidth) * maxLine);
  };

  const renderAppTiles = (apps) => {
    const sortedApps = apps.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    return (
      <Grid container spacing={3}>
        {sortedApps.map((item, index) => (
          <Grid item xl={2} lg={3} xs={4} key={index}>
            <div className="app-tile" key={`step-3-app-${index}`}>
              <AppTooltip
                hide={!showTitleTooltip}
                title={item.name}
                placement="top"
              >
                <div
                  className="app-title"
                  onMouseEnter={(e) => checkEllipsis(e, "app-title", 1)}
                >
                  {item.name}
                </div>
              </AppTooltip>
            </div>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderProjectDetails = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs className="no-padding-bottom">
          <div className="project-details-item">
            <div className="project-details-label">
              {t.project_project_category}
            </div>
            <div className="project-details-value">{projectCategory}</div>
          </div>
          <div className="project-details-item">
            <div className="project-details-label">
              {t.project_confidential_client_label}
            </div>
            <div className="project-details-value">{projectConfidential}</div>
          </div>
          <div className="project-details-item">
            <div className="project-details-label">{t.project_area}</div>
            <div className="project-details-value">{projectArea}</div>
          </div>
          <div className="project-details-item">
            <div className="project-details-label">{t.project_sector}</div>
            <div className="project-details-value">{projectSector}</div>
          </div>
          <div className="project-details-item">
            <div className="project-details-label">{t.type}</div>
            <div className="project-details-value">{projectType}</div>
          </div>
        </Grid>
        <Grid item xs className="no-padding-bottom">
          {approverFieldState &&
            approverFieldState.selectedValue &&
            !!approverFieldState.selectedValue.length && (
              <div className="project-details-item">
                <div className="project-details-label">{t.ppedd_approver}</div>
                <div className="project-details-value">{ppeddApprover}</div>
              </div>
            )}
          {paceFieldState &&
            paceFieldState.selectedValue &&
            !!paceFieldState.selectedValue.length && (
              <div className="project-details-item">
                <div className="project-details-label">{t.project_pace_id}</div>
                <div className="project-details-value">{paceProject}</div>
              </div>
            )}
          {!approverFieldState.selectedValue?.length &&
            !paceFieldState.selectedValue?.length && (
              <div className="project-details-item">
                <div className="project-details-label">{t.pace_pped_title}</div>
                <div className="project-details-value">{t.not_applicable}</div>
              </div>
            )}
          <div className="project-details-item">
            <div className="project-details-label">{t.project_client_name}</div>
            <div className="project-details-value">{projectClientName}</div>
          </div>

          <div className="project-details-item">
            <div className="project-details-label">{t.project_region}</div>
            <div className="project-details-value">{projectRegion}</div>
          </div>
          <div className="project-details-item">
            <div className="project-details-label">{t.project_sub_sector}</div>
            <div className="project-details-value">{projectSubSector}</div>
          </div>
          <div className="project-details-item">
            <div className="project-details-label">
              {t.project_service_line}
            </div>
            <div className="project-details-value">{projectServiceLine}</div>
          </div>
        </Grid>
        <Grid item xs className="no-padding-bottom">
          <div className="project-details-item">
            <div className="project-details-label">{t.project_name}</div>
            <div className="project-details-value">{projectName}</div>
          </div>
          <div className="project-details-item">
            <div className="project-details-label">{t.project_client_size}</div>
            <div className="project-details-value">{clientSize}</div>
          </div>
          <div className="project-details-item">
            <div className="project-details-label">{t.project_country}</div>
            <div className="project-details-value">{projectCountry}</div>
          </div>
          <div className="project-details-item">
            <div className="project-details-label">
              {t.project_sub_sub_sector}
            </div>
            <div className="project-details-value">{projectSubSubSector}</div>
          </div>

          <div className="project-details-item">
            <div className="project-details-label">
              {t.project_data_hosting_location}
            </div>
            <div className="project-details-value">{projectDataLocation}</div>
          </div>
        </Grid>
        <Grid item xs={12} className="no-padding-top">
          <div className="project-details-item">
            <div className="project-details-label">{t.project_desc}</div>
            <AppTooltip
              hide={!showTitleTooltip}
              title={projectDescription}
              placement="top"
            >
              <div
                className="project-details-value project-description"
                onMouseEnter={(e) => checkEllipsis(e, "project-description", 3)}
              >
                {projectDescription || "-"}
              </div>
            </AppTooltip>
          </div>
        </Grid>
      </Grid>
    );
  };

  const renderProjectPolicy = (projectCategoryId) => {
    const selectedCategory = rest.projectCategories.find(
      (c) => c.id === projectCategoryId
    );

    if (selectedCategory) {
      return selectedCategory.expirationPolicyText || "";
    }
    return "";
  };

  return (
    <div className="confirm-create-new-project">
      <div className="section-title">
        <span>{t.confirm_and_create_selected_apps}</span>
        <AppButton
          id="edit-selectedapps-btn"
          size="medium"
          color="secondary"
          onClick={() => {
            handleStepClick(1);
          }}
        >
          <AppIcon icon={editPen} className="edit-btn-icon" />
          {t.edit}
        </AppButton>
      </div>
      <div className="selected-apps-details">{renderAppTiles(apps)}</div>
      <div className="section-title">
        <span>{t.project_details_page_title}</span>
        <AppButton
          id="edit-project-details-btn"
          size="medium"
          color="secondary"
          onClick={() => {
            handleStepClick(2);
          }}
        >
          <AppIcon icon={editPen} className="edit-btn-icon" />
          {t.edit}
        </AppButton>
      </div>
      <div className="project-details">{renderProjectDetails()}</div>
      <div className="section-title"></div>
      <div className="project-MFA-details">
        <AppCheckbox
          name={formState.projectMFAEnabled.fieldName}
          value={formState.projectMFAEnabled.fieldVal}
          disabled={true}
          checked={projectMFAFlag}
          children={t.project_mfa}
        />
        <span className="mfa-tool-tip">
          <AppTooltip placement="top" title={t.mfa_tooltip_text}>
            <AppIcon icon={info} />
          </AppTooltip>
        </span>
      </div>
      <div className="policy-text-wrapper">
        {!!(
          formState.projectCategory.fieldVal &&
          formState.projectCategory.fieldVal.length
        ) && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<AppIcon icon={downArrow}></AppIcon>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{t.project_policy_title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="policy-text">
                {formState.projectCategory.fieldVal ===
                EProjectCategory.CLI_ENG ? (
                  <PricingProvisionPolicy
                    valid={formState.isPricingIncluded.isvalid}
                    value={{
                      price:
                        formState.isPricingIncluded.fieldVal === true
                          ? "Yes"
                          : formState.isPricingIncluded.fieldVal === false
                          ? "No"
                          : undefined,
                    }}
                    onUpdate={() => {}}
                  />
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: renderProjectPolicy(
                        formState.projectCategory.fieldVal
                      ),
                    }}
                  ></span>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </div>
  );
};

export default ConfirmAndCreateProject;
