import React, { FC } from "react";
import { AppButton, AppTooltip, AppIcon } from "../../theme";
import { arrowRight } from "../../theme/icons";
import FrozenFooter from "../../components/frozenFooter/frozenFooter";
import t from "../../localization/en/translation.json";

import "./projectFooter.scss";

interface IProps {
  saveBtnText: string;
  showCreateBtn?: boolean;
  saveHandler?(): void;
  createHandler?(): void;
  cancelHandler?(): void;
  disableAll?: boolean | number;
  disableSaveBtn?: boolean;
  showSaveBtn?: boolean;
  showUpdateBtn?: boolean;
  updateHandler?(): void;
  disableUpdatebtn?: boolean;
  isCreateProjectDisabled?: boolean;
  isMaintanenceFlagLoaded?: boolean;
  disableContinueBtn?: boolean;
  showContinueBtn?: boolean;
  onContinueBtnClick?(): void;
  showBackBtn?: boolean;
  onBackBtnClick?(): void;
}

const CreateProjectFooter: FC<IProps> = ({
  saveBtnText,
  showCreateBtn,
  disableAll,
  saveHandler,
  createHandler,
  cancelHandler,
  disableSaveBtn,
  showSaveBtn,
  showUpdateBtn,
  disableUpdatebtn,
  updateHandler,
  isCreateProjectDisabled,
  disableContinueBtn,
  showContinueBtn,
  onContinueBtnClick,
}) => {

  return (
    <FrozenFooter>
      <div className="project-footer-container">
        <div className="footer-action-panel">
          <AppButton
            className="cancel-btn"
            size="medium"
            color="secondary"
            disabled={disableAll ? true : false}
            onClick={() => cancelHandler && cancelHandler()}
          >
            {t.cancel}
          </AppButton>
          {showSaveBtn !== false && (
            <AppButton
              className={`save-btn ${(((disableAll ? true : false) || disableSaveBtn) && "disable") ||
                "active"
                }`}
              color="secondary"
              disabled={(disableAll ? true : false) || disableSaveBtn}
              onClick={() => saveHandler && saveHandler()}
            >
              {saveBtnText}
            </AppButton>
          )}
          {showCreateBtn && (
            <AppTooltip
              placement="top"
              hide={!isCreateProjectDisabled}
              title={
                t.admin_portal_maintanence_flag_enabled_create_project_disabled_tooltip
              }
            >
              <AppButton
                className={
                  isCreateProjectDisabled ? "btn-disabled" : "create-btn"
                }
                color="primary"
                onClick={() => createHandler && createHandler()}
              >
                {t.create_project}
                <AppIcon icon={arrowRight} className="next-btn-icon" />
              </AppButton>
            </AppTooltip>
          )}
          {showUpdateBtn && (
            <AppButton
              className={`create-btn`}
              color="primary"
              onClick={updateHandler}
              disabled={disableUpdatebtn || (disableAll ? true : false)}
            >
              {t.project_update_button_label}
              {!showContinueBtn && <AppIcon icon={arrowRight} className="next-btn-icon" />}
            </AppButton>
          )}
          {showContinueBtn &&
            <AppButton
              className="next-btn"
              size="medium"
              color="primary"
              disabled={(disableAll ? true : false) || disableContinueBtn}
              onClick={() => onContinueBtnClick && onContinueBtnClick()}
            >
              {t.next}
              <AppIcon icon={arrowRight} className="next-btn-icon" />
            </AppButton>
          }
        </div>
      </div>
    </FrozenFooter>
  );
};

export default CreateProjectFooter;
