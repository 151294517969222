import React, { FC, useEffect } from "react";
import "./frozenFooter.scss";

const FrozenFooter: FC = (props) => {

	useEffect(() => {
		document.body.classList.add('with-sticky-footer');

		return function cleanup() {
			document.body.classList.remove('with-sticky-footer');
		};
	}, []);

	return (
		<div className="frozen-footer">
			{props.children}
		</div>
	)
}

export default FrozenFooter;